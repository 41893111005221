<template>
        <h6 class="mt-3 pt-2 mb-1 border-top">{{$t('Saved Filters')}}</h6>
        <Overlay v-if="filterObject.filtersListDO.state.isLoading"/>
        <div class="flex flex-column" style="overflow-y:auto">
            <FiltersList :filterObject="filterObject" showTemplates></FiltersList>
            <!--
            <h6 class="mt-1 pt-2 mb-0" v-if="filterObject.filtersListDO.data.filter(x=>!x.mine).length">{{$t('Shared Filters')}} </h6>
            -->
                <div class="form-check form-switch" v-if="filterObject.filtersListDO.data.filter(x=>!x.mine && x.Hidden).length">
                    <input class="form-check-input" type="checkbox" id="_include_hidden" v-model="includeHidden">
                    <label class="form-check-label" for="_include_hidden">{{$t('Include hidden')}}</label>
                </div>
        
            <FiltersList :filterObject="filterObject" showTemplates sharedOnly v-if="filterObject.filtersListDO.data.filter(x=>!x.mine).length" :includeHidden = "includeHidden"></FiltersList>
            </div>

</template>

<script setup>
    import { defineProps,ref,nextTick} from "vue";
   // import ListItem from 'o365.vue.components.Filters.ListItem.vue';
    import FiltersList from 'o365.vue.components.Filters.List.vue';
    import { Overlay } from 'o365.vue.components.jsx';
    components:{}
    const props = defineProps({
        filterObject:null,
        showTemplates:{
            type:Boolean,
            default:false,
        }
    });
    const includeHidden = ref(false);
    
</script>
